<template>
    <div class="pageBox">
        <!-- <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>             
            </el-col>
        </el-row> -->
        <div>
            <div class="NewPage" style="padding:10px 0;position: relative;">
                <div class="newPageChilBox">
                    <div class="chilBoxTitle">可发通知总人数</div>
                    <div class="chilBoxNumber" style="display: flex;"><div @click="viewH5DetailData()" style="cursor: pointer;">{{NumOfDirData.SumOfUsedForSMS}}</div></div>
                    <div>数据中台来源人数 {{NumOfDirData.SumOfNumFromSXPT}}</div>
                    <div>自行填报人数 {{NumOfDirData.SumOfNumBySelf}}</div>
                    <div>大数据甄选 {{NumOfDirData.SumOfNumFromOtherPlat}}</div>
                    <!-- <div>自主注册 {{NumOfDirData.SumOfNumFromRegister}}</div> -->
                </div>
                <div class="newPageChilBox">
                    <div class="chilBoxTitle">待人工清洗数据</div>
                    <div class="chilBoxNumber" style="display: flex;"><div @click="viewPendingClearnData()" style="cursor: pointer;">{{NumOfDirData.SumOfNumForNoClean}}</div></div>
                    <div>相同单位手机号重复数 {{NumOfDirData.SumOfNumForSameUnitPhone}},排重后数量{{NumOfDirData.SumOfSameUnitDoWeightPhone}}</div>
                    <div>不同单位手机号重复数 {{NumOfDirData.SumOfNumForDirUnitPhone}},排重后数量{{NumOfDirData.SumOfDirUnitDoWeightPhone}}</div>
                    <div>号码不符合规则 {{NumOfDirData.SumOfNumForIllegalPhone}}</div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div style="position: absolute;right: 25px;top: 0px;">
                <el-button type="text" @click="toNewMenulistDetails">
                    查看详情
                </el-button>
            </div>
        </div>
        <div>
            <el-form :inline='true'>
                <el-form-item label="">
                    <el-select v-model="selectData" style="width: 400px;margin-top: 30px;">
                        <el-option
                            v-for="item in selectDataArr"
                            :key="item.Id"
                            :label="item.Name"
                            :value="item.Id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <div class="NewPage" style="border: none;">
            <div class="newPageChilBox">
                <div class="chilBoxTitle">距发送通知</div>
                <div class="chilBoxNumber">{{sendSMSPreview.NoticedDayNum}}<span style="font-size: 16px;font-weight: 100;margin-left: 15px;">天</span></div>
                <div>发送通知时间：{{sendSMSPreview.NoticeTime}}</div>
            </div>
            <div class="newPageChilBox">
                <div class="chilBoxTitle">通知后响应率</div>
                <div class="chilBoxNumber">{{this.redefinitionDecimalFun(sendSMSPreview.ReplyRateOfNoticed)}}</div>
                <div v-for="(item,index) in sendSMSPreview.ReplyDetailNumList" :key="index">{{item.Description}}：{{item.Num}}</div>
                <div v-if="sendSMSPreview.FillStaffNumList" style="margin-top: -10px;">填报情况：
                    <el-popover
                        placement="top"
                        width=""
                        trigger="hover"
                    >
                        <div v-for="(item,index) in sendSMSPreview.FillStaffNumList" :key="index">{{item.Description}}：{{item.Num}}</div>
                        <el-button type="text" slot="reference">查看</el-button>
                    </el-popover>
                </div>
            </div>
            <div class="newPageChilBox">
                <div class="chilBoxTitle">通知成功率</div>
                <div class="chilBoxNumber">{{this.redefinitionDecimalFun(sendSMSPreview.RateOfSuccessNotice)}}</div>
                <div>通知总人数：{{sendSMSPreview.NoticeNum}}</div>
                <div>发送短信人数：{{sendSMSPreview.CurNoticeNum}}</div>
                <div>短信回执成功：{{sendSMSPreview.ReceiptSuccessNum}}</div>
                <div>短信回执未知：{{sendSMSPreview.ReceiptUnKnownNum}}</div>
                <div><el-button @click="toFailedSMSresend()" type="text" style="margin-top: -10px;">短信回执失败：{{sendSMSPreview.ReceiptFailNum}}</el-button></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div>
            <div id="lineTable" v-if="isShowLineTable" style="width: 100%;text-align: center;height: 200px;"></div>
        </div>
        <div class="chartTitle">
            通知后10天响应人数
        </div>
        <div class="pieBox">
            <div>
                <div>
                    <div id="pieTableLeft" style="width: 400px;height: 250px;"></div>
                </div>
                <div class="pieTitle">各地级市通知人数分布</div>
            </div>
            <div>
                <div>
                    <div id="pieTableRight" style="width: 400px;height: 250px;"></div>
                </div>
                <div class="pieTitle">通知后地级市响应率分布</div>
            </div>
        </div>
        <!-- 未访问H5页面-选择人员/未访问H5页面-新建短信群发 -->
        <el-dialog
            :title="dialogTitle"
            :visible.sync="viewH5Dialog"
            v-model="viewH5Dialog"
            :close-on-click-modal='false'
            width="65%"
        >
            <div v-if="dialogPage == 1">
                <div style="margin-bottom: 20px;">总人数：{{noH5DataObj.NumOfSum ? noH5DataObj.NumOfSum : 0}}</div>
                <el-form :inline="true" :model="viewH5Form" label-width="130px" :rules="viewH5Rules" ref="viewH5ref">
                    <el-form-item label="通知次数：">
                        <el-select v-model="viewH5Form.notification" multiple collapse-tags ref='notificationRef' placeholder="请选择通知次数" clearable>
                            <el-option
                                v-for="item in notificationsArr"
                                :key="item.value"
                                :label="item.Name"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="地区：">
                        <el-cascader :props="props" v-model="viewH5Form.selecAreaArr" ref='cascader' placeholder="请选择地区" clearable></el-cascader>
                    </el-form-item>
                    <el-form-item label="岗位（人群）：">
                        <el-select v-model="viewH5Form.stationID" ref='stationIDRef' placeholder="请选择岗位（人群）" clearable>
                            <el-option
                                v-for="item in stationArr"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="行业（场所）：">
                        <el-select v-model="viewH5Form.tradeID" ref='tradeIDRef' placeholder="请选择行业（场所）" clearable>
                            <el-option
                                v-for="item in tradeArr"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="上次通知时间：">
                        <el-select v-model="viewH5Form.lastNotificeTime" ref='lastNotificeTimeRef' placeholder="请选择上次通知时间" clearable style="width: 180px;">
                            <el-option
                                v-for="item in lastNotificeTimeArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                        天以上
                    </el-form-item> -->
                    <!-- <el-form-item label="上次通知时间：">
                        <el-date-picker
                            ref='lastNotificeTimeRef'
                            v-model="viewH5Form.lastNotificeTime"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </el-form-item> -->
                    <el-form-item label="是否自行填报：">
                        <el-select v-model="viewH5Form.isSelfReport" ref='isSelfReportRef' placeholder="请选择是否自行填报" clearable>
                            <el-option
                                v-for="item in commonStatusArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="是否免培：">
                        <el-select v-model="viewH5Form.isFreeTrain" ref='isFreeTrainRef' placeholder="请选择是否免培" clearable>
                            <el-option
                                v-for="item in commonStatusArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item> -->
                    <!-- <el-form-item label="是否下载APP：">
                        <el-select v-model="viewH5Form.isDownAPP" ref='isDownAPPRef' placeholder="请选择是否下载APP" clearable>
                            <el-option
                                v-for="item in commonStatusArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item> -->
                    <div class="searchBtnBox">
                        <el-button @click="noH5Search()" type="primary" plain size="small" style="margin-right: 60px;">重新查询</el-button>
                    </div>
                </el-form>
                <el-divider></el-divider>
                <!-- <div class="searchBox">
                    <div>共查询出{{noH5DataObj.NumOfSum}}人，
                        <span v-if="noH5DataObj.NoticeCountResponseList && noH5DataObj.NoticeCountResponseList.length > 0">
                            <span v-for="(item,index) in noH5DataObj.NoticeCountResponseList" :key="index">
                                其中通知{{item.NoticeCount}}次有{{item.NumOfNoticeCount}}人；
                            </span>
                        </span>
                    </div>
                    <div v-if="LnewSelecAreaArr">{{LnewSelecAreaArr}}地区的有{{noH5DataObj.AreaCount}}人；</div>
                    <div v-if="LstationID">{{LstationID}}岗位（人群）的有{{noH5DataObj.StationCount}}人；</div>
                    <div v-if="LtradeID">{{LtradeID}}行业（场所）的有{{noH5DataObj.TradeCount}}人；</div>
                    <div v-if="LlastNotificeTime">上次通知在{{LlastNotificeTime}}天以上的有{{noH5DataObj.LastNoticeTimeCount}}人；</div>
                    <div v-if="LisSelfReport">{{LisSelfReport}}的有{{noH5DataObj.FullBySelfCount}}人；</div>
                    <div v-if="LisFreeTrain">{{LisFreeTrain}}的有{{noH5DataObj.ExamNoTrainCount}}人；</div>
                    <div v-if="LisDownAPP">{{LisDownAPP}}的有{{noH5DataObj.DownloadCount}}人；</div>
                </div> -->
                <div class="searchBox">
                    <div>共查询出{{noH5DataObj.NumOfSum}}人</div>
                </div>
                <div class="searchBox" v-if="!showSearchDetail">
                    <div><span style="font-weight: bold;">通知次数：</span>
                        <span v-if="noH5DataObj.NoticeCountResponseList && noH5DataObj.NoticeCountResponseList.length > 0">
                            <span v-for="(item,index) in noH5DataObj.NoticeCountResponseList" :key="index">
                                <span>{{item.NoticeCount}}次{{item.NumOfNoticeCount}}人；</span>
                            </span>
                        </span>
                    </div>
                    <div><span style="font-weight: bold;">地区：</span>
                        <span v-if="noH5DataObj.AreaCountList && noH5DataObj.AreaCountList.length > 0">
                            <span v-for="(item,index) in noH5DataObj.AreaCountList" :key="index">
                                <span v-if="item.Count > 0">{{item.CityName}}{{item.Count}}人； </span>
                            </span>
                        </span>
                    </div>
                    <div><span style="font-weight: bold;">岗位：</span>
                        <span v-if="noH5DataObj.StationCountList && noH5DataObj.StationCountList.length > 0">
                            <span v-for="(item,index) in noH5DataObj.StationCountList" :key="index">
                                <span v-if="item.Count > 0">{{item.StationName}}{{item.Count}}人； </span>
                            </span>
                        </span>
                    </div>
                    <div><span style="font-weight: bold;">行业：</span>
                        <span v-if="noH5DataObj.TradeCountList && noH5DataObj.TradeCountList.length > 0">
                            <span v-for="(item,index) in noH5DataObj.TradeCountList" :key="index">
                                <span v-if="item.Count > 0">{{item.TradeName}}{{item.Count}}人； </span>
                            </span>
                        </span>
                    </div>
                </div>
                <div slot="footer" class="dialog-footer" style="text-align: right;">
                    <el-button @click.native="viewH5Dialog = false">取消</el-button>
                    <el-button type="primary" @click.native="nextSubmit" :loading="nextLoading">下一步</el-button>
                </div>
            </div>
            <div v-if="dialogPage == 2">
                <el-form :model="applyForSendSMSForm" :rules="applyForSendSMSRules" ref="applyForSendSMSRef" label-width="120px" style='min-width="800px"'>
                    <el-form-item label="群发对象:" prop="name">
                        {{sendGroupName}}
                    </el-form-item>
                    <el-form-item label="通知名称:" prop="name">
                        <el-input v-model='applyForSendSMSForm.name' placeholder="请输入通知名称"></el-input>
                    </el-form-item>
                    <el-form-item label="模板分类:" prop="modelType">
                        新增待通知
                    </el-form-item>
                    <el-form-item label="模板名称:" prop="SMSTemp">
                        <el-select v-model="applyForSendSMSForm.SMSTemp" placeholder="请选择短信模板" clearable>
                            <span v-if="applyForSendSMSForm.modelType == 0">
                                <el-option
                                    v-for="item in smsTemplateArr"
                                    :key="item.Id"
                                    :label="item.Name"
                                    :value="item.Id"
                                >
                                </el-option>
                            </span>
                            <span v-if="applyForSendSMSForm.modelType == 1">
                                <el-option
                                    v-for="item in pushTemplateArr"
                                    :key="item.ID"
                                    :label="item.Name"
                                    :value="item.ID"
                                >
                                </el-option>
                                <el-pagination
                                    layout="prev, pager, next"
                                    @current-change="changeIndex"
                                    :total="pushTempPages.dataCount">
                                </el-pagination>
                            </span>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="模板内容:">
                        <div class="modelContent">
                            {{modelContent}}
                        </div>
                    </el-form-item>
                    <el-form-item label="最大限额:" prop="batchMax">
                        <el-input
                            type="number"
                            placeholder="请输入最大限额"
                            v-model="applyForSendSMSForm.batchMax"
                            clearable
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item label='发送时间:' prop="sendSMSType">
                        <el-radio-group v-model="applyForSendSMSForm.sendSMSType">
                            <el-radio :label="0">立即发送</el-radio>
                        </el-radio-group>
                    </el-form-item>         
                    <el-form-item label=''>
                        <el-radio-group v-model="applyForSendSMSForm.sendSMSType">
                            <el-radio :label="1">定时发送</el-radio>
                        </el-radio-group>
                        <span style="margin-left:20px;">
                            <el-date-picker
                                v-model="applyForSendSMSForm.sendSMSTime"
                                :disabled="applyForSendSMSForm.sendSMSType == 0"
                                type="datetime"
                                editable
                                clearable
                                :picker-options="pickerOptions"
                                placeholder="选择日期时间"
                            >
                            </el-date-picker>
                        </span>
                    </el-form-item>         
                </el-form>
                <div slot="footer" class="dialog-footer" style="text-align: right;">
                    <el-button type="primary" @click.native="sendSMSSubmit" :loading="sendSMSLoading">申请发送</el-button>
                    <el-button @click.native="viewH5Dialog = false">取消</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
var echarts = require('echarts');
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import { getNumOfDirResource,getBatchOfStatus,getDataOfBatch,getChartDataOfBatch,getAreaDictionaryList,applyForStatusSendSMS,getNoticeTemplateByStatus,getDataDictionaryList,getSocialGroupNumByCondition } from '@/api/api'
import util from "../../../util/date";
import router from '@/router'
import Qs from 'qs';
export default {
    components: {Toolbar},
    data() {
        return{
            NumOfDirData: {
                SumOfUsedForSMS: 0,
                SumOfNumFromSXPT: 0,
                SumOfNumBySelf: 0,
                SumOfNumFromOtherPlat: 0,
                SumOfNumFromRegister: 0,
                SumOfNumForNoClean: 0,
                SumOfNumForSameUnitPhone: 0,
                SumOfSameUnitDoWeightPhone: 0,
                SumOfNumForDirUnitPhone: 0,
                SumOfDirUnitDoWeightPhone: 0,
                SumOfNumForIllegalPhone: 0,
            },
            selectData: '',
            selectDataArr: [],
            sendSMSPreview: {
                NoticedDayNum: 0,
                NoticeTime: "-",
                ReplyRateOfNoticed: 0,
                ReplyNumOfNoticed: 0,
                RateOfSuccessNotice: 0,
                NoticeNum: 0,
                CurNoticeNum: 0,
                ReceiptSuccessNum: 0,
                ReceiptUnKnownNum: 0,
                SuccessNoticeNum: 0,
                ReceiptFailNum:0
            },
            // 未访问H5页面-选择人员/未访问H5页面-新建短信群发
            dialogPage: 1,
            dialogTitle: "新名单-选择人员",
            viewH5Dialog: false,
            nextLoading: false,
            sendSMSLoading: false,
            smsTemplateArr: [],
            sendGroupName: "",
            viewH5Form: {
                notification: [],
                selecAreaArr: [],
                stationID: "",
                tradeID: "",
                lastNotificeTime: "",
                isSelfReport: "",
                isFreeTrain: "",
                isDownAPP: "",
            },
            noH5DataObj: {
                lastNotiNum: "",
                NumOfSum: 0,
                LastNoticeTimeCount: 0,
                FullBySelfCount: 0,
            },
            viewH5Rules: {
                notification: [{ required: true, message: "请选择通知次数", trigger: "blur" }],
                selecAreaArr: [{ required: true, message: "请选择地区", trigger: "blur" }]
            },
            notificationsArr: [
                {value: 0,name: "0"},
                {value: 1,name: "1"},
                {value: 2,name: "2"},
                {value: 3,name: "3"},
                {value: 4,name: "4"},
                {value: 5,name: "5"},
                {value: 6,name: "6"},
            ],
            stationArr: [],
            tradeArr: [],
            lastNotificeTimeArr: [
                {value: 0,name: 3},
                {value: 1,name: 7},
                {value: 2,name: 10},
                {value: 3,name: 15},
                {value: 4,name: 30},
                {value: 5,name: 60},
                {value: 6,name: 90},
                {value: 7,name: 120},
            ],
            commonStatusArr: [
                {value: true,name: "是"},
                {value: false,name: "否"},
            ],
            applyForSendSMSForm: {
                name: '',
                SMSTemp: '',
                SMStarget: 0,
                SMSContent: '',
                nameList: '',
                sendSMSType: 0,
                sendSMSTime: '',
                batchMax: '',
                modelType: '',
            },
            modelContent: '',
            applyForSendSMSRules:{
                name:[{required:true,message:'请选择通知名称',trigger:'blur'}],
                modelType:[{required:true,message:'请选择模板类型',trigger:'blur'}],
                SMSTemp:[{required:true,message:'请选择短信模板',trigger:'blur'}],
                SMStarget:[{required:true,message:'请选择群发对象',trigger:'blur'}],
                nameList:[{required:true,message:'请选择名单',trigger:'blur'}],
                SMSContent:[{required:true,message:'请输入手机号',trigger:'blur'}],
                sendSMSType:[{required:true,message:'请选择发送时间',trigger:'blur'}],
                batchMax:[{required:true,message:'请输入最大限额',trigger:'blur'}],
            },
            pickerOptions:{
                disabledDate:(time)=> {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            // 地区级联选择
            props: {
                lazy: true,
                checkStrictly: true,
                lazyLoad (node, resolve) {
                    var level = false
                    if(node.value > 0) {
                        var params = {
                            parentId: node.value,
                            level: 2,
                        }
                        level = true
                    } else {
                        var params = {
                            parentId: 1919,
                            level: 1,
                        }
                        level = false
                    }
                    getAreaDictionaryList(params).then(res => {
                        if(res.data.Success){
                            var nodes = res.data.Response
                            nodes.forEach(item => {
                                item.value = item.ID
                                item.label = item.Name
                                item.leaf = level
                            })
                            resolve(nodes);
                        }
                    })
                }
            },
            LnewSelecAreaArr: "",
            LstationID: "",
            LtradeID: "",
            LlastNotificeTime: "",
            LisSelfReport: "",
            LisFreeTrain: "",
            LisDownAPP: "",
            showSearchDetail: false,
            isShowLineTable: true,
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 点击事件
        viewH5DetailData() {
            var that = this
            this.dialogTitle = "新名单-选择人员",
            this.viewH5Dialog = true
            this.nextLoading = false
            this.dialogPage = 1
            this.sendGroupName = ""
            this.viewH5Form = {
                notification: [],
                selecAreaArr: [],
                stationID: "",
                tradeID: "",
                lastNotificeTime: "",
                isSelfReport: "",
                isFreeTrain: "",
                isDownAPP: "",
            },
            this.applyForSendSMSForm = {
                name: '',
                SMSTemp: '',
                SMStarget: 0,
                SMSContent: '',
                nameList: '',
                sendSMSType: 0,
                sendSMSTime: '',
                batchMax: '',
                modelType: '',
            }
            setTimeout(() => {
                that.noH5Search(true)
            });
        },
        // 带人工清洗数据
        viewPendingClearnData() {
            router.push({
                path: "/NotificationEffect/PendingClearData",
                query: {}
            });
        },
        // 下一步
        nextSubmit() {
            var newDate = this.formatCreateTime1(new Date())
            // var selecAreaArr = this.viewH5Form.selecAreaArr ? this.$refs.selecAreaArrRef.selected.currentLabel : ""
            var selecAreaArr = this.$refs['cascader'].getCheckedNodes()
            var newSelecAreaArr
            if (selecAreaArr && selecAreaArr[0]) {
                const A = selecAreaArr[0].pathLabels
                if (A) {
                    newSelecAreaArr = (A[0] ? A[0] : "") + (A[1] ? A[1] : "") + (A[2] ? A[2] : "") + (A[3] ? A[3] : "")
                }
            }
            var notification = this.viewH5Form.notification.length > 0 ? ("通知" + this.viewH5Form.notification + "次") : ""
            var stationID = this.viewH5Form.stationID ? (this.$refs.stationIDRef.selected.currentLabel) : ""
            var tradeID = this.viewH5Form.tradeID ? (this.$refs.tradeIDRef.selected.currentLabel) : ""
            var lastNotificeTime = "" //this.viewH5Form.lastNotificeTime ? ("上次通知" + this.$refs.lastNotificeTimeRef.selected.currentLabel + "天以上") : ""
            // var lastNotificeTime = this.viewH5Form.lastNotificeTime ? ("上次通知" + this.formatCreateTime(this.viewH5Form.lastNotificeTime[0]) + "至" + this.formatCreateTime(this.viewH5Form.lastNotificeTime[1])) : ""
            var isSelfReport = this.$refs.isSelfReportRef.selected.currentLabel == "是" ? "已自行填报" : (this.$refs.isSelfReportRef.selected.currentLabel == "否" ? "未自行填报" : "")
            var isFreeTrain = "" //this.$refs.isFreeTrainRef.selected.currentLabel == "是" ? "已免培" : (this.$refs.isFreeTrainRef.selected.currentLabel == "否" ? "未免培" : "")
            var isDownAPP = "" //this.$refs.isDownAPPRef.selected.currentLabel == "是" ? "已下载APP" : (this.$refs.isDownAPPRef.selected.currentLabel == "否" ? "未下载APP" : "")

            var sendGroupName = newDate + (newSelecAreaArr ? newSelecAreaArr : "") + (notification ? notification :"") + (stationID ? stationID :"") + (tradeID ? tradeID :"") + (lastNotificeTime ? lastNotificeTime :"") + (isSelfReport ? isSelfReport :"") + (isFreeTrain ? isFreeTrain :"") + (isDownAPP ? isDownAPP :"") + "新名单"
            this.sendGroupName = sendGroupName
            this.applyForSendSMSForm.name = sendGroupName
            this.nextLoading = true
            this.dialogPage = 2
            this.sendSMSLoading = false
            this.dialogTitle = "新名单-新建短信群发"
        },
        // 申请发送短信
        sendSMSSubmit() {
            this.$refs['applyForSendSMSRef'].validate(valid => {
                if(valid){
                    if(this.applyForSendSMSForm.sendSMSType == 1 && !this.applyForSendSMSForm.sendSMSTime) {
                        this.$message.warning("请选择日期时间")
                        return
                    } else {
                        var selTime = Math.round(new Date(this.applyForSendSMSForm.sendSMSTime).getTime() / 1000)
                        if(selTime < Math.round(new Date().getTime() / 1000)){
                            this.$message.warning("请选择当前之后时间")
                            return
                        }
                    }
                    var params = {}
                    // var params = {
                    //     Name: this.applyForSendSMSForm.name,
                    //     NoticeTemplateID: this.applyForSendSMSForm.SMSTemp,
                    //     SendObjectStatus: this.applyForSendSMSForm.SMStarget,
                    //     SendTimeType: this.applyForSendSMSForm.sendSMSType,
                    //     ModelType: this.applyForSendSMSForm.modelType,
                    //     BatchMax: parseInt(this.applyForSendSMSForm.batchMax),
                    // }
                    // if(this.applyForSendSMSForm.SMStarget == 0) {
                    //     params.SocialGroupID = this.applyForSendSMSForm.nameList
                    // } else {
                    //     params.Phone = this.applyForSendSMSForm.SMSContent
                    // }
                    // if(this.applyForSendSMSForm.sendSMSType == 1) {
                    //     params.NoticeTime = this.timeChange(this.applyForSendSMSForm.sendSMSTime)
                    // }


                    var SocialGroupConditionRequest = {}
                    SocialGroupConditionRequest.NoticeCounts = this.viewH5Form.notification.length > 0 ? this.viewH5Form.notification : []
                    SocialGroupConditionRequest.ProvinceID = this.viewH5Form.selecAreaArr[0] ? 1919 : ""
                    SocialGroupConditionRequest.CityID = this.viewH5Form.selecAreaArr[0] ? this.viewH5Form.selecAreaArr[0] : ""
                    SocialGroupConditionRequest.RegionID = this.viewH5Form.selecAreaArr[1] ? this.viewH5Form.selecAreaArr[1] : ""
                    SocialGroupConditionRequest.StationID = this.viewH5Form.stationID
                    SocialGroupConditionRequest.TradeID = this.viewH5Form.tradeID
                    // SocialGroupConditionRequest.NumOfDaysSinceLastNotice = this.viewH5Form.lastNotificeTime
                    SocialGroupConditionRequest.StartDate = this.viewH5Form.lastNotificeTime ? this.formatCreateTime(this.viewH5Form.lastNotificeTime[0]) : ""
                    SocialGroupConditionRequest.EndDate = this.viewH5Form.lastNotificeTime ? this.formatCreateTime(this.viewH5Form.lastNotificeTime[1]) : ""
                    SocialGroupConditionRequest.IsFullBySelf = this.viewH5Form.isSelfReport
                    SocialGroupConditionRequest.IsExamNoTrain = this.viewH5Form.isFreeTrain
                    SocialGroupConditionRequest.IsDownload = this.viewH5Form.isDownAPP
                    SocialGroupConditionRequest.Status = 1000
                    var ApplyForSendSMSResponse = {}
                    ApplyForSendSMSResponse.SendObjectName = this.sendGroupName
                    ApplyForSendSMSResponse.Name = this.applyForSendSMSForm.name
                    ApplyForSendSMSResponse.ModelType = 0
                    ApplyForSendSMSResponse.NoticeTemplateID = this.applyForSendSMSForm.SMSTemp
                    ApplyForSendSMSResponse.SendTimeType = this.applyForSendSMSForm.sendSMSType
                    ApplyForSendSMSResponse.BatchMax = parseInt(this.applyForSendSMSForm.batchMax)
                    if(this.applyForSendSMSForm.sendSMSType == 1) {
                        ApplyForSendSMSResponse.NoticeTime = this.timeChange(this.applyForSendSMSForm.sendSMSTime)
                    }
                    if(this.applyForSendSMSForm.SMStarget == 0) {
                        ApplyForSendSMSResponse.SocialGroupID = this.applyForSendSMSForm.nameList
                    } else {
                        ApplyForSendSMSResponse.Phone = this.applyForSendSMSForm.SMSContent
                    }
                    params.SocialGroupConditionRequest = SocialGroupConditionRequest
                    params.ApplyForSendSMSResponse = ApplyForSendSMSResponse
                    this.sendSMSLoading = true
                    applyForStatusSendSMS(params).then(res => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message)
                            this.viewH5Dialog = false
                        } else {
                            this.$message.error(result.Message)
                        }
                        this.sendSMSLoading = false
                    })
                }
            })
        },
        // 获取短信模板
        getSmsTimplateFun() {
            var params = {
                type: 1000,
            }
            getNoticeTemplateByStatus(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.smsTemplateArr = result.Response
                }
            })
        },
        // 未访问H5页面选择人员查询
        noH5Search(val) {
            var newSelecAreaArr
            var selecAreaArr = this.$refs['cascader'].getCheckedNodes()
            if (selecAreaArr && selecAreaArr[0]) {
                const A = selecAreaArr[0].pathLabels
                if (A) {
                    newSelecAreaArr = (A[0] ? A[0] : "") + (A[1] ? A[1] : "") + (A[2] ? A[2] : "") + (A[3] ? A[3] : "")
                }
            }
            var stationID = this.viewH5Form.stationID ? (this.$refs.stationIDRef.selected.currentLabel) : ""
            var tradeID = this.viewH5Form.tradeID ? (this.$refs.tradeIDRef.selected.currentLabel) : ""
            // var lastNotificeTime = this.viewH5Form.lastNotificeTime ? this.$refs.lastNotificeTimeRef.selected.currentLabel : ""
            var isSelfReport = this.$refs.isSelfReportRef.selected.currentLabel == "是" ? "已自行填报" : (this.$refs.isSelfReportRef.selected.currentLabel == "否" ? "未自行填报" : "")
            // var isFreeTrain = this.$refs.isFreeTrainRef.selected.currentLabel == "是" ? "已免培" : (this.$refs.isFreeTrainRef.selected.currentLabel == "否" ? "未免培" : "")
            // var isDownAPP = this.$refs.isDownAPPRef.selected.currentLabel == "是" ? "已下载APP" : (this.$refs.isDownAPPRef.selected.currentLabel == "否" ? "未下载APP" : "")
            this.LnewSelecAreaArr = newSelecAreaArr ? newSelecAreaArr : ""
            this.LstationID = stationID ? stationID : ""
            this.LtradeID = tradeID ? tradeID : ""
            // this.LlastNotificeTime = lastNotificeTime ? lastNotificeTime : ""
            this.LisSelfReport = isSelfReport ? isSelfReport : ""
            // this.LisFreeTrain = isFreeTrain ? isFreeTrain : ""
            // this.LisDownAPP = isDownAPP ? isDownAPP : ""
            if(val) {
                this.showSearchDetail = true
            } else {
                this.showSearchDetail = false
            }
            var params = {
                status: 1000,
                noticeCounts: this.viewH5Form.notification.length > 0 ? this.viewH5Form.notification.join(",") : "",
                provinceID: this.viewH5Form.selecAreaArr[0] ? 1919 : "",
                cityID: this.viewH5Form.selecAreaArr[0],
                regionID: this.viewH5Form.selecAreaArr[1],
                townID: "",
                stationID: this.viewH5Form.stationID,
                tradeID: this.viewH5Form.tradeID,
                // numOfDaysSinceLastNotice: this.viewH5Form.lastNotificeTime,
                startDate: this.viewH5Form.lastNotificeTime ? this.formatCreateTime(this.viewH5Form.lastNotificeTime[0]) : "",
                endDate: this.viewH5Form.lastNotificeTime ? this.formatCreateTime(this.viewH5Form.lastNotificeTime[1]) : "",
                isFullBySelf: this.viewH5Form.isSelfReport,
                isExamNoTrain: this.viewH5Form.isFreeTrain,
                isDownload: this.viewH5Form.isDownAPP,
            }
            getSocialGroupNumByCondition(params).then(res => {
                var result = res.data
                if(result.Success && result.Response) {
                    this.noH5DataObj = result.Response
                }
            })
            // this.noH5DataObj.lastNotiNum = this.$refs.lastNotificeTimeRef.selected.currentLabel
        },
        // 获取可发短信总人数/待人工清洗数据
        getNumDataFun() {
            var params = {
                noticeType: 1000,
            }
            getNumOfDirResource(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.NumOfDirData = result.Response
                }
            })
        },
        // 获取发送短信批次数据
        getSMSBatchDataFun() {
            var params = {
                noticeType: 1000,
            }
            getBatchOfStatus(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.selectDataArr = result.Response.reverse()
                    this.selectData = result.Response[0].Id
                    this.getsendSMSPreviewFun()
                    this.getViewlineTable()
                }
            })
        },
        // 获取新名单/未访问H5页面发送通知概况预览
        getsendSMSPreviewFun() {
            var params = {
                noticeType: 1000,
                socialGroupID: this.selectData
            }
            getDataOfBatch(params).then(res => {
                var result = res.data
                if(result.Success) {
                    result.Response.ReplyDetailNumList = this.sortDataFun(result.Response.ReplyDetailNumList)
                    this.sendSMSPreview = result.Response
                }
            })
        },
        // 排序
        sortDataFun(newLineArr) {
            if(newLineArr && newLineArr.length > 0) {
                for(var a = 0; a < 7; a++) {
                    if(a == 0) {
                        for(var i = 0; i < newLineArr.length; i++) {
                            if(newLineArr[i].name == "取证人数" || newLineArr[i].Description == "取证人数") {
                                newLineArr.push(newLineArr[i])
                                newLineArr.splice(i,1)
                                break;
                            }
                        }
                    } else if(a == 1) {
                        for(var i = 0; i < newLineArr.length; i++) {
                            if(newLineArr[i].name == "学习人数" || newLineArr[i].Description == "学习人数") {
                                newLineArr.push(newLineArr[i])
                                newLineArr.splice(i,1)
                                break;
                            }
                        }
                    } else if(a == 2) {
                        for(var i = 0; i < newLineArr.length; i++) {
                            if(newLineArr[i].name == "报考人数" || newLineArr[i].Description == "报考人数") {
                                newLineArr.push(newLineArr[i])
                                newLineArr.splice(i,1)
                                break;
                            }
                        }
                    } else if(a == 3) {
                        for(var i = 0; i < newLineArr.length; i++) {
                            if(newLineArr[i].name == "注册人数" || newLineArr[i].Description == "注册人数") {
                                newLineArr.push(newLineArr[i])
                                newLineArr.splice(i,1)
                                break;
                            }
                        }
                    } else if(a == 4) {
                        for(var i = 0; i < newLineArr.length; i++) {
                            if(newLineArr[i].name == "单位填报数" || newLineArr[i].Description == "单位填报数") {
                                newLineArr.push(newLineArr[i])
                                newLineArr.splice(i,1)
                                break;
                            }
                        }
                    } else if(a == 5) {
                        for(var i = 0; i < newLineArr.length; i++) {
                            if(newLineArr[i].name == "访问H5页面人数" || newLineArr[i].Description == "访问H5页面人数") {
                                newLineArr.push(newLineArr[i])
                                newLineArr.splice(i,1)
                                break;
                            }
                        }
                    } else if(a == 6) {
                        for(var i = 0; i < newLineArr.length; i++) {
                            if(newLineArr[i].name == "响应人数" || newLineArr[i].Description == "响应人数") {
                                newLineArr.push(newLineArr[i])
                                newLineArr.splice(i,1)
                                break;
                            }
                        }
                    }
                }
                return newLineArr.reverse()
            } else {
                return null
            }
        },
        // 图表
		getViewlineTable(){
            var that = this
            var params = {
                noticeType: 1000,
                socialGroupID: this.selectData
            }
            this.isShowLineTable = false
            setTimeout(() => {
                that.isShowLineTable = true
            })
            getChartDataOfBatch(params).then(res => {
                var result = res.data
                if(result.Success) {
                    // 线性图
                    var Lname = []
                    var newLineArr = []
                    if(result.Response && result.Response.NumOfNoticedByDate && result.Response.NumOfNoticedByDate.length > 0) {
                        result.Response.NumOfNoticedByDate.forEach(item => {
                            Lname = []
                            var newObj = {
                                name: "",
                                type: 'line',
                                // stack: 'Total',
                                data: []
                            }
                            item.forEach(o => {
                                Lname.push(this.formatCreateTime2(o.Date))
                                newObj.name = o.StatusName
                                newObj.data.push(o.ReplyNum)
                            })
                            newLineArr.push(newObj)
                        });
                    }
                    newLineArr = this.sortDataFun(newLineArr)
                    var lineTableChart = echarts.init(document.getElementById('lineTable'));
                    var colorList = ['#DF3AB9', '#18C79C', '#8555F9', '#F09228','#39B3FF','#47E0E0','#7891D9','#83D978','#C7A530','#FF8439','#39B3FF','#DF3AB9', '#18C79C', '#8555F9', '#F09228','#39B3FF','#47E0E0','#7891D9','#83D978','#C7A530','#FF8439','#39B3FF'];
                    lineTableChart.setOption({
                        // legend: {
                        //     data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
                        // },
                        tooltip: {
                            trigger: 'axis', //折线图滑上提示框显示
                            formatter: function(params) {
                                var newHtml = ""
                                params.forEach((item,index) => {
                                    if(item.value/that.sendSMSPreview.ReceiptSuccessNum > 0.001) {
                                        newHtml += "<div style='display: flex;align-items: center;'><div style='width: 12px;margin-right: 5px;height: 12px;background: " + colorList[index] + ";border-radius: 50%;'></div><span>" + item.seriesName + "：</span><span> " + item.value + "</span><span>&nbsp;&nbsp; 比例： " + that.redefinitionDecimalFun(item.value/that.sendSMSPreview.ReceiptSuccessNum) + "</span></div>"
                                    } else {
                                        newHtml += "<div style='display: flex;align-items: center;'><div style='width: 12px;margin-right: 5px;height: 12px;background: " + colorList[index] + ";border-radius: 50%;'></div><span>" + item.seriesName + "：</span><span> " + item.value + "</span></div>"
                                    }
                                })
                                return newHtml
                            }
                        },
                        color: colorList,
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            top: '5%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            data: Lname
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: newLineArr,
                    })
                    // 饼状图左
                    var LeftPieObj = []
                    if(result.Response && result.Response.NumOfNoticedByArea && result.Response.NumOfNoticedByArea.length > 0) {
                        result.Response.NumOfNoticedByArea.forEach(item => {
                            var newObj = {
                                value: item.NoticeNum, name: item.CityName
                            }
                            LeftPieObj.push(newObj)
                        });
                    }
                    var pieTableChart = echarts.init(document.getElementById('pieTableLeft'));
                    pieTableChart.setOption({
                        tooltip: {
                            trigger: 'item',
                        },
                        series: [
                            {
                                name: '查看',
                                type: 'pie',
                                radius: '50%',
                                data: LeftPieObj,
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 30,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            }
                        ]
                    })
                    // 饼状图右
                    var rightPieObj = []
                    if(result.Response && result.Response.RateOfNoticedByArea && result.Response.RateOfNoticedByArea.length > 0) {
                        result.Response.RateOfNoticedByArea.forEach(item => {
                            var newObj = {
                                value: item.ReplyRate, name: item.CityName
                            }
                            rightPieObj.push(newObj)
                        });
                    }
                    var pieTableChart = echarts.init(document.getElementById('pieTableRight'));
                    pieTableChart.setOption({
                        tooltip: {
                            trigger: 'item',
                            formatter: function (params) {
                                return '<div style="line-height: 24px;text-align: start;color: #000">' + params.name + ' 响应率：' + that.redefinitionDecimalFun(params.value) + '</div>'
                            }
                        },
                        series: [
                            {
                                name: '查看',
                                type: 'pie',
                                radius: '50%',
                                data: rightPieObj,
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            }
                        ]
                    })
                }
            })
		},
        // 查看详情
        toNewMenulistDetails() {
            router.push({
                path: "/NewMenulistDetails",
                query: {isShowBack: true}
            });
        },
        // 短信回执失败重发
        toFailedSMSresend() {
            router.push({
                path: "/FailedSMSresend",
                query: {socialGroupID: this.selectData,noticeType: 1000}
            });
        },
        // 获取岗位试卷
        getStationOrTradePaper() {
            var params = {}
            getDataDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.stationArr = []
                    this.tradeArr = []
                    result.Response.forEach(item => {
                        if(item.DataType === 1) {
                            this.stationArr.push(item)
                        } else if(item.DataType === 0) {
                            this.tradeArr.push(item)
                        }
                    })
                }
            })
        },
        redefinitionDecimalFun(val) {
			if(val && val > 0) {
				if(val < 0.001) {
					return 0.1 + '%'
				} else {
					var num = val
					// 不四舍五入向下取整
					// var num = (Math.floor(val * 100) / 100) * 100
					// 四舍五入
					// var num = (Math.round(val * 100) / 100) * 100
					if(num > 1 || num == 1) {
						var resultNum = 100
					} else {
						// if((num + '').length > 5) {
						// 	var resultNum = num.toString().slice(0,2)
						// } else {
						// 	var resultNum = num
						// }
						var newVal = ((num * 100).toFixed(1)).slice(0,4).replace('.00', '')
						var resultNum = newVal.replace('.0', '')
						if(String(newVal).slice(-2) == '.0') {
							resultNum = newVal.replace('.0', '')
						} else {
							resultNum = newVal
						}
					}
					return resultNum + '%'
				}
			} else {
				return 0
			}
		},
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row || row == ""
            ? ""
            : util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm:ss");
        },
        formatCreateTime1: function(row, column) {
            return !row || row == ""
            ? ""
            : util.formatDate.format(new Date(row), "yyyyMMdd");
        },
        formatCreateTime2: function(row, column) {
            return !row || row == ""
            ? ""
            : util.formatDate.format(new Date(row), "yyyy-MM-dd");
        },
    },
    watch: {
        'applyForSendSMSForm.modelType': function(val) {
            this.applyForSendSMSForm.SMSTemp = ""
            this.modelContent = ""
        },
        'applyForSendSMSForm.SMSTemp': function(val) {
            if(this.applyForSendSMSForm.modelType == 0) {
                this.smsTemplateArr.forEach(item => {
                    if(item.Id == val) {
                        this.modelContent = item.Content
                    }
                })
            } else {
                this.pushTemplateArr.forEach(item => {
                    if(item.ID == val) {
                        this.modelContent = item.Content
                    }
                })
            }
        },
        'selectData': function(val) {
            var that = this
            that.selectData = val
            setTimeout(() => {
                that.getsendSMSPreviewFun()
                that.getViewlineTable()
            })
        }
    },
    mounted() {
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
    mounted() {
        this.getNumDataFun()
        this.getSMSBatchDataFun()
        this.getStationOrTradePaper()
        this.getSmsTimplateFun()
        // this.getViewPieTableLeft()
        // this.getViewPieTableRight()
    },
}
</script>

<style lang="stylus" scoped>
    .el-textarea>>>.el-textarea__inner {
        min-height 150px!important;
    }
    .pageBox {
        width: 98%;
        margin: 0 auto;
        padding-bottom: 30px;
    }
    .NewPage {
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
        border: 1px solid #C0C4CC;
        // padding: 15px;
    }
    .newPageChilBox {
        width: 300px;
        line-height: 20px;
        font-size: 14px;
    }
    .chilBoxTitle {
        font-size: 16px
    }
    .chilBoxNumber {
        font-weight: bold;
        font-size: 22px;
        margin: 14px 0;
    }
    .chartTitle {
        // margin: 15px 0;
        width: 100%;
        text-align: center;
        font-size: 14px;
    }
    .pieBox {
        display: flex;
        justify-content: space-around;
    }
    .pieTitle {
        width: 100%;
        text-align: center;
        font-size: 14px;
    }
    .searchBtnBox {
        text-align: right;
    }
    .searchBox {
        line-height: 40px;
    }
    .el-form-item {
        margin-bottom: 10px!important;
    }
</style>